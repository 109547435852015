import { Component, OnInit } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";

@Component({
  selector: "app-state",
  templateUrl: "./state.component.html",
  styleUrls: ["./state.component.css"]
})
export class StateComponent implements OnInit {
  state: string;
  user: any;
  constructor(private amplifyService: AmplifyService) {
    this.amplifyService.authStateChange$.subscribe(authState => {
      this.state = authState.state;
      if (!authState.user) {
        this.user = null;
      } else {
        this.user = authState.user;
      }
    });
  }

  setState() {
    //  this.amplifyService.setAuthState({ state: "confirmSignIn" }, this.user);
  }

  ngOnInit() {}
}
