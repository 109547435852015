import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import Amplify from "aws-amplify";

// AUTOMATIC Amplify config using CLI
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

// MANUAL Amplify config
// Amplify.configure({
//   Auth: {
//     // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//     // identityPoolId: "32f9b7ff-eb1c-4cf0-95e8-87a9e856e870",

//     // REQUIRED - Amazon Cognito Region
//     region: "us-west-2",

//     // OPTIONAL - Amazon Cognito Federated Identity Pool Region
//     // Required only if it's different from Amazon Cognito Region
//     // identityPoolRegion: "XX-XXXX-X",

//     // OPTIONAL - Amazon Cognito User Pool ID
//     userPoolId: "us-west-2_RXVO0CB98",

//     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//     userPoolWebClientId: "467qh56gocjfb6tld74atupb11",

//     // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
//     // mandatorySignIn: false,

//     // OPTIONAL - Configuration for cookie storage
//     // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
//     // cookieStorage: {
//     //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
//     //   domain: ".yourdomain.com",
//     //   // OPTIONAL - Cookie path
//     //   path: "/",
//     //   // OPTIONAL - Cookie expiration in days
//     //   expires: 365,
//     //   // OPTIONAL - Cookie secure flag
//     //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
//     //   secure: true
//     // },

//     // OPTIONAL - customized storage object
//     // storage: new MyStorage(),

//     // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
//     // authenticationFlowType: "USER_PASSWORD_AUTH",

//     // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
//     // clientMetadata: { myCustomKey: "myCustomValue" },

//     // OPTIONAL - Hosted UI configuration
//     oauth: {
//       domain: "chadbumstead.auth.us-west-2.amazoncognito.com",
//       scope: [
//         "phone",
//         "email",
//         "profile",
//         "openid",
//         "aws.cognito.signin.user.admin"
//       ],
//       redirectSignIn: "https://id.chadbumstead.com/",
//       redirectSignOut: "https://id.chadbumstead.com/",
//       responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
//     }
//   }
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
