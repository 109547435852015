// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:bfc857d9-50d9-4754-9408-223690a0913d",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_BB1rstdJ2",
    "aws_user_pools_web_client_id": "35af4pcboo41988ckp5eajesp0",
    "oauth": {
        "domain": "idyvyiy350263a1-350263a1-id.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:4200/",
        "redirectSignOut": "http://localhost:4200/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
