import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  title = "id";
  signUpConfig = {
    header: "My Customized Sign Up",
    hideAllDefaults: true,
    defaultCountryCode: "1",
    signUpFields: [
      {
        label: "Email",
        key: "email",
        required: true,
        displayOrder: 2,
        type: "string"
      },
      {
        label: "Password",
        key: "password",
        required: true,
        displayOrder: 1,
        type: "password"
      },
      {
        label: "Phone Number",
        key: "phone_number",
        required: true,
        displayOrder: 3,
        type: "string"
      },
      {
        label: "Custom Attribute",
        key: "custom_attr",
        required: false,
        displayOrder: 4,
        type: "string",
        custom: true
      }
    ]
  };

  ngOnInit() {}
}
