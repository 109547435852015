import { Component, OnInit } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import { Auth } from "aws-amplify";

@Component({
  selector: "app-auto",
  templateUrl: "./auto.component.html",
  styleUrls: ["./auto.component.css"]
})
export class AutoComponent implements OnInit {
  user: any;
  constructor(private amplifyService: AmplifyService) {}

  async signIn() {
    this.user = await Auth.signIn("cbumstead@gmail.com", "Q)n`$gm'6[J6");
    console.log(this.user);
  }

  ngOnInit() {}
}
